import { useEffect, useState } from "react"
import PropTypes from "prop-types"

const FormError = ({ errorFor, errors }) => {
  const error = errors[errorFor]
  const [inputField, setInputField] = useState(null)

  useEffect(() => {
    if (document) {
      setInputField(document.querySelector(`#${errorFor}`))
    }
  }, [])

  const errorMessage = inputField && !inputField.value ? "" : error
  return <span className={"error-message"}>{errorMessage} &nbsp;</span>
}

const SubmissionBanner = ({
  success = true,
  message = "Message sent",
  show = false,
}) => {
  const flashMessage = success ? message : "Sorry, your message was not sent"
  const alertType = success ? "success" : "danger"

  return (
    show && (
      <div
        className={`alert alert-${alertType} alert-dismissible fade show`}
        role="alert"
      >
        {flashMessage}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
  )
}

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [displayFlash, setDisplayFlash] = useState(false)
  const [success, setSuccess] = useState(false)

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if ([name, email, subject, message].some(field => !field.length)) {
      return setHasErrors(true)
    }

    const url = `${process.env.GATSBY_AGRITEER_CMS_API_URL}/support`
    const data = JSON.stringify({ name, email, subject, message })

    if (hasErrors) return

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.GATSBY_AGRITEER_CMS_API_KEY,
      },
      body: data,
    })
      .then(res => res.json())
      .then(() => {
        setName("")
        setEmail("")
        setSubject("")
        setMessage("")
        setDisplayFlash(true)
        setSuccess(true)
      })
      .catch(() => {
        setDisplayFlash(true)
        setSuccess(false)
      })
  }

  useEffect(() => {
    if (name.length < 3) {
      setErrors(prevErrors => ({ ...prevErrors, name: "Name is too short" }))
    } else {
      setErrors(prevErrors => ({ ...prevErrors, name: undefined }))
    }

    if (!validateEmail(email)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: "Email format is incorrect",
      }))
    } else {
      setErrors(prevErrors => ({ ...prevErrors, email: undefined }))
    }

    if (subject.length < 10) {
      setErrors(prevErrors => ({
        ...prevErrors,
        subject: "Subject is too short",
      }))
    } else {
      setErrors(prevErrors => ({ ...prevErrors, subject: undefined }))
    }

    if (message.length < 50) {
      setErrors(prevErrors => ({
        ...prevErrors,
        message: "Message is too short",
      }))
    } else {
      setErrors(prevErrors => ({ ...prevErrors, message: undefined }))
    }

    if (Object.keys(errors).some(k => !!errors[k])) {
      setHasErrors(true)
    } else {
      setHasErrors(false)
    }
  }, [name, email, subject, message])

  return (
    <section id="contact" name="contact" className="row feedback-form">
      <h4>Be part of our story</h4>

      <p>We will love to hear from you</p>

      <SubmissionBanner show={displayFlash} success={success} />

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            placeholder="Your name"
            aria-label="Your name"
            type="text"
            id="name"
            className="form-control"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <FormError errorFor="name" errors={errors} />
        </div>

        <div className="form-group">
          <input
            placeholder="Your email"
            aria-label="Your email"
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormError errorFor="email" errors={errors} />
        </div>

        <div className="form-group">
          <input
            placeholder="Subject"
            aria-label="Subject"
            type="text"
            id="subject"
            className="form-control"
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
          <FormError errorFor="subject" errors={errors} />
        </div>

        <div className="form-group">
          <textarea
            rows="5"
            placeholder="Message"
            aria-label="Message"
            id="message"
            className="form-control"
            value={message}
            onChange={e => setMessage(e.target.value)}
          ></textarea>
          <FormError errorFor="message" errors={errors} />
        </div>
        <button
          type="submit"
          className="btn btn-success btn-submit"
          disabled={hasErrors}
        >
          Send
        </button>
      </form>
    </section>
  )
}

FormError.propTypes = {
  errorFor: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
}

export default ContactForm
