import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faLinkedin,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons"

const year = new Date().getFullYear()

export default function Footer() {
  return (
    <section className="footer">
      <section className="row col-md-6">
        <div className="col-md-4">
          <p className="footer__title">get to know us</p>

          <p>
            <a href="#home">about us</a>
          </p>
          <p>
            <a href="#impact">our impact</a>
          </p>
          <p>
            <a href="#services">our services</a>
          </p>
        </div>

        <div className="col-md-4">
          <p className="footer__title">connect</p>

          <p>
            <a href="#contact">contact us</a>
          </p>
        </div>
      </section>

      <section className="row social">
        <section className="col-md-6 footer__left">
          <p className="footer__copyright">
            &copy; All rights reserved Agriteer {year}
          </p>
        </section>
        <section className="footer__right col-md-6">
          <a
            href="https://facebook.com/Agriteer"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className="fa-icon"
              size="2x"
              color="white"
              icon={faFacebookSquare}
              name="facebook"
            />
          </a>

          <a
            href="https://twitter.com/agriteer"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className="fa-icon"
              size="2x"
              color="white"
              icon={faTwitterSquare}
              name="twitter"
            />
          </a>

          <a
            href="https://www.instagram.com/agriteer_ng"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className="fa-icon"
              size="2x"
              color="white"
              icon={faInstagramSquare}
              name="instagram"
            />
          </a>

          <a
            href="https://www.linkedin.com/company/agriteer"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className="fa-icon"
              size="2x"
              color="white"
              icon={faLinkedin}
              name="linkedin"
            />
          </a>

          <a
            href="https://www.youtube.com/channel/UC0G4qyNCASpClpDtp6Y_bfg/about"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className="fa-icon"
              size="2x"
              color="white"
              icon={faYoutubeSquare}
              name="youtube"
            />
          </a>
        </section>
      </section>
    </section>
  )
}
