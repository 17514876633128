import { graphql, useStaticQuery } from "gatsby"
import { useState } from "react"
import Image from "gatsby-image"
import NavModal from "./NavModal"

export default function Navbar() {
  const [showModal, setShowModal] = useState(false)

  const agriteerLogo = useStaticQuery(graphql`
    query {
      white: file(name: { eq: "agriteer_logo" }) {
        childCloudinaryAsset {
          fixed {
            ...CloudinaryAssetFixed
          }
        }
      }
      green: file(name: { eq: "agriteer_logo_gr" }) {
        childCloudinaryAsset {
          fixed {
            ...CloudinaryAssetFixed
          }
        }
      }
    }
  `)

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <a className="navbar-brand white" href="/">
        <Image
          fixed={agriteerLogo.white.childCloudinaryAsset.fixed}
          style={{ height: "40px", width: "163px" }}
          alt="Agriteer logo"
        />
      </a>

      <a className="navbar-brand green" href="/">
        <Image
          fixed={agriteerLogo.green.childCloudinaryAsset.fixed}
          style={{ height: "40px", width: "163px" }}
          alt="Agriteer logo"
        />
      </a>

      <button
        type="button"
        aria-label="Toggle navigation"
        className="navbar-toggler"
        data-toggle="modal"
        aria-expanded="false"
        data-target="#exampleModal"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <NavModal showModal={showModal} closeModal={() => setShowModal(false)} />

      <div
        className="justify-content-end navbar-collapse collapse"
        id="responsive-navbar-nav"
      >
        <div className="navbar-nav">
          <a href="#home" data-rb-event-key="#home" className="nav-link">
            About Us
          </a>
          <a href="#impact" data-rb-event-key="#impact" className="nav-link">
            Impact
          </a>
          <a
            href="#services"
            data-rb-event-key="#services"
            className="nav-link"
          >
            Our Services
          </a>
          <a href="#contact" data-rb-event-key="#contact" className="nav-link">
            Contact
          </a>
        </div>
      </div>
    </nav>
  )
}
