const NavModal = () => (
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <ul className="navbar-nav">
          <button
            type="button"
            className="btn-close close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <li
            href="#home"
            data-rb-event-key="#home"
            className="nav-link close"
            data-dismiss="modal"
            onClick={() => (window.location.href = "#home")}
            role="link"
          >
            About Us
          </li>

          <li
            href="#impact"
            data-rb-event-key="#impact"
            className="nav-link close"
            data-dismiss="modal"
            onClick={() => (window.location.href = "#impact")}
            role="link"
          >
            Impact
          </li>

          <li
            href="#services"
            data-rb-event-key="#services"
            className="nav-link close"
            data-dismiss="modal"
            onClick={() => (window.location.href = "#services")}
            role="link"
          >
            Our Services
          </li>

          <li
            href="#contact"
            data-rb-event-key="#contact"
            className="nav-link close"
            data-dismiss="modal"
            onClick={() => (window.location.href = "#contact")}
            role="link"
          >
            Contact
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default NavModal
