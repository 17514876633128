import { useEffect } from "react"
import Footer from "../components/Footer"
import ContactForm from "../components/ContactForm"
import Jumbotron from "../components/Jumbotron"
import Navbar from "../components/Navbar"
import Impact from "../components/Impact"
import Statement from "../components/Statement"
import Services from "../components/Services"

import "./index.css"
import "./index.mobile.css"

export default function Home() {
  const handleScroll = () => {
    const navbar = document.querySelector(".fixed-top")

    if (window.pageYOffset >= 72) return navbar.classList.add("scrolled")
    return navbar.classList.remove("scrolled")
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <div className="container-fluid">
      <Navbar />
      <Jumbotron />
      <Impact />
      <Statement />
      <Services />
      <ContactForm />
      <Footer />
    </div>
  )
}
