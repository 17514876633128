import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"

export default function Jumbotron() {
  const data = useStaticQuery(graphql`
    query {
      silos: file(name: { eq: "silos" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
      harvester: file(name: { eq: "harvester" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
      solar: file(name: { eq: "solar" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="div"
      className="jumbotron row"
      fluid={data.solar.childCloudinaryAsset.fluid}
      backgroundColor={"#698EBA"}
      id="home"
      name="home"
    >
      <section className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <h3 className="jumbotron__header">
          Reducing Post-harvest loss one community at a time.
        </h3>

        <p className="jumbotron__tagline">
          Agriteer is building an agricultural services ecosystem for farmers to
          add value to their crops and reduce post-harvest loss one community at
          a time.
        </p>

        <p className="jumbotron__cta">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => (window.location.href = "#contact")}
          >
            <span className="jumbotron__cta-parts">Get in touch</span>
            <span className="jumbotron__cta-parts">
              <FontAwesomeIcon
                className="fa-icon"
                size="lg"
                color="white"
                icon={faLongArrowAltRight}
              />
            </span>
          </button>
        </p>
      </section>

      <section className="row col-lg-6 col-md-12 col-md-6 col-sm-12 col-xs-12 jumbotron__images">
        <div className="col-md-6 col-sm-12 col-xs-12 jumbotron__image">
          <Image
            fluid={data.harvester.childCloudinaryAsset.fluid}
            alt="harvester"
          />
        </div>

        <div className="col-md-6 col-sm-12 col-xs-12 jumbotron__image right">
          <Image fluid={data.silos.childCloudinaryAsset.fluid} alt="silos" />
        </div>
      </section>
    </BackgroundImage>
  )
}
