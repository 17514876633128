import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      farmer: file(name: { eq: "Farmer" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
      farmField: file(name: { eq: "farm_field" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      className="row row-section services"
      id="services"
      name="services"
      Tag="section"
      fluid={data.farmField.childCloudinaryAsset.fluid}
      preserveStackingContext={true}
    >
      <section className="col-lg-6 col-md-12 col-sm-12 col-xs-12 left">
        <h3>Our Services</h3>

        <h4>
          We are developing communities of small holder farmers and an
          agricultural services ecosystem, one town at a time.
        </h4>

        <section className="row service-story">
          <section className="service-section col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <p className="heading">Logistics</p>

            <p className="description">
              Right from your harvest, we bring the best hands, knowledge and
              expertise to preserve your crops, prevent squashing and rapid
              decay. we provide the best handling and logistics that your crop
              requires to prolong its shelf life and arrive safely at the
              designated destination.
            </p>
          </section>

          <section className="service-section col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <p className="heading">Processing</p>

            <p className="description">
              Adding value to produce by providing processing facilities for
              farmers enable farmers to earn more from their produce. We provide
              access to processing facilities, giving farmers the opportunity to
              add value to their crops.
            </p>
          </section>

          <section className="service-section col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <p className="heading">Storage and Preservation</p>

            <p className="description">
              Many small holder farmers lose their crops to rot because they
              cannot access the necessary storage facility to preserve their
              crops. We are giving the farmer an opportunity to preserve their
              produce until they are ready to ship to the market.
            </p>
          </section>

          <section className="service-section col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <p className="heading">Marketing and Linkage</p>

            <p className="description">
              We work with farmers to ensure that they have ready markets for
              their produce before harvest thereby reducing the wait period of
              the produce, after harvest.
            </p>
          </section>
        </section>
      </section>

      <section className="row col-lg-6 col-md-12 col-md-6 col-sm-8 col-xs-10 right centralize stay-down">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Image fluid={data.farmer.childCloudinaryAsset.fluid} alt="Farmer" />
        </div>
      </section>
    </BackgroundImage>
  )
}
