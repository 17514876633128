import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

export default function Statement() {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "corn-farmer" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)

  return (
    <section
      className="row row-section statement"
      id="statement"
      name="statement"
    >
      <section className="row col-lg-6 col-md-12 col-md-6 col-sm-12 col-xs-12 left">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Image
            fluid={data.file.childCloudinaryAsset.fluid}
            alt="corn farmer"
          />
        </div>
      </section>

      <section className="col-lg-6 col-md-12 col-sm-12 col-xs-12 right">
        <h4>
          At Agriteer, we believe that optimizing post harvest processes by
          improving storage, logistics, information sharing and collaboration
          among all stakeholders is an important step to curb losses.
        </h4>
      </section>
    </section>
  )
}
