import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

export default function Impact() {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "tomatoes" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)

  return (
    <section className="row row-section impact" id="impact" name="impact">
      <section className="col-lg-6 col-md-12 col-sm-12 col-xs-12 left">
        <h4>
          Almost a quarter of the total food production in Nigeria is wasted due
          to post harvest losses. In financial terms, this accounts for a loss
          of about 9 billion US dollars in revenue.
        </h4>

        <p>
          This loss of revenue greatly and directly impacts the livelihood of
          small holder farmers who contribute about 80 percent of the
          agricultural production in Nigeria, largely contributing to food
          insecurity.
        </p>

        <p>
          Addressing this problem will require cooperation and partnership among
          all stakeholders in the industry. A deep understanding of the varying
          factors from the point of harvest and the prevalent stages that these
          losses occur, is the key to providing a long lasting solution.
        </p>
      </section>

      <section className="row col-lg-6 col-md-12 col-md-6 col-sm-12 col-xs-12 right">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <Image fluid={data.file.childCloudinaryAsset.fluid} alt="Tomatoes" />
        </div>
      </section>
    </section>
  )
}
